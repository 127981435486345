/*----------------------------------------
  09. Blog style
----------------------------------------*/

.single-blog-wrap {
    position: relative;
}

.blog-img {
	height: 100%;
	padding: 0;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 56.5%;
	right: 0;
	z-index: 2;
    overflow: hidden;
    @media #{$md-layout} {
        width: inherit;
        position: relative;
        transform: inherit;
        margin: 0 40px;
    }
    @media #{$xs-layout} {
        width: inherit;
        position: relative;
        transform: inherit;
        margin: 0 15px;
    }
    > a {
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            transition: all .4s ease 0s;
        }
    }
    .video-popup-2 {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        a {
            display: inline-block;
            width: 129px;
            height: 129px;
            line-height: 129px;
            border: 1px solid $white;
            border-radius: 100%;
            text-align: center;
            transform: scale(1);
            transition: all .4s ease 0s;
            @media #{$xs-layout} {
                width: 80px;
                height: 80px;
                line-height: 77px;
            }
            svg {
                path {
                    stroke: $white;
                    transition: all .3s ease 0s;
                }
            }
            &:hover {
                background-color: $white;
                svg {
                    path {
                        stroke: $black;
                    }
                }
            }
        }
        &:hover {
            a {
                transform: scale(.9);
            }
        }
    }
    &:hover {
        > a {
            img {
                transform: scale(1.08);
            }
        }
    }
}

.blog-content {
	padding: 166px 85px 210px 140px;
    @media #{$xx-layout} {
        padding: 120px 50px 120px 50px;
    }
    @media #{$xl-layout} {
        padding: 100px 15px 100px 15px;
    }
    @media #{$lg-layout} {
        padding: 70px 20px 70px 30px;
    }
    @media #{$md-layout} {
        padding: 40px 20px 0px 40px;
    }
    @media #{$xs-layout} {
        padding: 35px 20px 0px 15px;
    }
    .blog-meta {
        ul {
            display: flex;
            flex-wrap: wrap;
            li {
                font-family: $proximanova;
                color: #3E3E3E;
                margin-right: 15px;
                position: relative;
                &::before {
                    content: '';
                    position: absolute;
                    right: -8px;
                    top: 50%;
                    transform: translateY(-50%) rotate(20deg);
                    width: 1px;
                    height: 12px;
                    background: #3E3E3E;
                }
                &:last-child {
                    margin-right: 0;
                    &::before {
                        display: none;
                    }
                }
                a {
                    color: #3E3E3E;
                    font-family: $proximanova;
                    &:hover {
                        color: $theme-color;
                    }
                }
            }
        }
    }
    h2 {
        font-size: 44px;
        line-height: 54px;
        letter-spacing: -1.63px;
        margin: 24px 0 28px;
        @media #{$xx-layout} {
            font-size: 40px;
        }
        @media #{$xl-layout} {
            font-size: 40px;
        }
        @media #{$lg-layout} {
            font-size: 30px;
            line-height: 47px;
            margin: 10px 0 15px;
        }
        @media #{$md-layout} {
            font-size: 30px;
            line-height: 47px;
            margin: 10px 0 15px;
        }
        @media #{$xs-layout} {
            font-size: 25px;
            line-height: 42px;
            margin: 10px 0 10px;
        }
    }
    p {
        font-size: 22px;
        line-height: 46px;
        margin: 0 0 50px;
        width: 90%;
        @media #{$xx-layout} {
            font-size: 20px;
            line-height: 42px;
            margin: 0 0 40px;
            width: 100%;
        }
        @media #{$xl-layout} {
            font-size: 20px;
            line-height: 42px;
            margin: 0 0 40px;
            width: 100%;
        }
        @media #{$lg-layout} {
            font-size: 16px;
            line-height: 32px;
            margin: 0 0 25px;
            width: 100%;
        }
        @media #{$md-layout} {
            font-size: 20px;
            line-height: 42px;
            margin: 0 0 40px;
            width: 100%;
        }
        @media #{$xs-layout} {
            font-size: 18px;
            line-height: 36px;
            margin: 0 0 22px;
            width: 100%;
        }
    }
}

.blog-btn {
    a {
        font-family: $proximanova;
        &.btn {
            padding: 15px 53px;
            @media #{$xs-layout} {
                padding: 13px 30px 14px;
            }
        }
        &.btn-outline-primary {
            border-color: #979797;
        }
    }
}

.blog-padding {
    padding: 180px 0;
    @media #{$lg-layout} {
        padding: 118px 0;
    }
    @media #{$md-layout} {
        padding: 100px 0;
    }
    @media #{$xs-layout} {
        padding: 70px 0;
    }
}

.blog-padding-2 {
    padding: 118px 0;
    @media #{$md-layout} {
        padding: 100px 0;
    }
    @media #{$xs-layout} {
        padding: 70px 0;
    }
}

.pagination-style {
    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        li {
            margin-right: 20px;
            @media #{$xs-layout} {
                margin-right: 10px;
                margin-bottom: 10px
            }
            &:last-child {
                margin-right: 0;
                a {
                    padding: 0px 25px;
                }
            }
            a {
                display: inline-block;
                min-width: 58px;
                min-height: 58px;
                line-height: 58px;
                border: 1px solid #D5D5D5;
                text-align: center;
                font-family: $proximanova;
                font-size: 18px;
                @media #{$xs-layout} {
                    min-width: 45px;
                    min-height: 45px;
                    line-height: 45px;
                    font-size: 16px;
                }
                &.active , &:hover {
                    border: 1px solid #434343;
                }
            }
        }
    }
}

.btn-outline-primary:focus {
	box-shadow: none;
}

@media #{$lg-layout} {
    .pagination-style {
        &.mt-65 {
            margin-top: 0px;
        }
    }
}

.blog-details-wrap {
    .blog-details-img {
        position: relative;
        overflow: hidden;
        img {
            width: 100%;
            transition: all .5s ease 0s;
        }
        .b-details-img-content {
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            text-align: center;
            h2 {
                color: $white;
                font-size: 44px;
                margin: 0;
                @media #{$lg-layout} {
                    font-size: 35px;
                }
                @media #{$md-layout} {
                    font-size: 27px;
                }
                @media #{$xs-layout} {
                    font-size: 23px;
                    padding: 0 10px;
                }
            }
        }
        &:hover {
            img {
                transform: scale(1.1);
            }
        }
    }
}

.blog-details-content {
    margin: 93px 51px 0;
    @media #{$xx-layout} {
        margin: 93px 0px 0;
    }
    @media #{$xl-layout} {
        margin: 93px 0px 0;
    }
    @media #{$lg-layout} {
        margin: 70px 0px 0;
    }
    @media #{$md-layout} {
        margin: 40px 0px 0;
    }
    @media #{$xs-layout} {
        margin: 35px 0px 0;
    }
    h2 {
        color: #212121;
        font-weight: bold;
        font-size: 36px;
        margin: 0 0 24px;
        @media #{$xs-layout} {
            font-size: 27px;
            margin: 0 0 20px;
        }
    }
    .blog-meta-2 {
        margin: 0 0 11px;
        ul {
            display: flex;
            flex-wrap: wrap;
            li {
                font-family: $proximanova;
                color: #3E3E3E;
                margin-right: 15px;
                position: relative;
                &::before {
                    content: '';
                    position: absolute;
                    right: -8px;
                    top: 50%;
                    transform: translateY(-50%) rotate(20deg);
                    width: 1px;
                    height: 12px;
                    background: #3E3E3E;
                }
                &:last-child {
                    margin-right: 0;
                    &::before {
                        display: none;
                    }
                }
                a {
                    color: #3E3E3E;
                    font-family: $proximanova;
                    &:hover {
                        color: $theme-color;
                    }
                }
            }
        }
    }
    p {
        font-size: 16px;
        line-height: 38px;
        margin: 0 0 14px;
        @media #{$xs-layout} {
            line-height: 32px;
        }
    }
    .blockquote-wrap {
        display: flex;
        align-items: center;
        margin: 100px 0 110px;
        @media #{$md-layout} {
            margin: 45px 0 50px;
        }
        @media #{$xs-layout} {
            display: block;
            margin: 40px 0 14px;
        }
        .blockquote-img {
            @media #{$xs-layout} {
                margin-bottom: 20px;
                text-align: center;
            }
            svg {
                path {
                    stroke: #979797;
                }
            }
        }
        .blockquote-content {
            margin-left: 97px;
            @media #{$xx-layout} {
                margin-left: 50px;
            }
            @media #{$xl-layout} {
                margin-left: 50px;
            }
            @media #{$lg-layout} {
                margin-left: 40px;
            }
            @media #{$md-layout} {
                margin-left: 40px;
            }
            @media #{$xs-layout} {
                margin-left: 0px;
                margin-bottom: 20px;
            }
            h3 {
                font-size: 36px;
                line-height: 49px;
                margin: 0;
                font-family: $proximanova;
                @media #{$xx-layout} {
                    font-size: 29px;
                    line-height: 45px;
                }
                @media #{$xl-layout} {
                    font-size: 28px;
                    line-height: 45px;
                }
                @media #{$lg-layout} {
                    font-size: 27px;
                    line-height: 45px;
                }
                @media #{$md-layout} {
                    font-size: 22px;
                    line-height: 40px;
                }
                @media #{$xs-layout} {
                    font-size: 20px;
                    line-height: 33px;
                }
            }
        }
    }
    .blog-tag-share-wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin: 49px 0 39px;
        .blog-tag-wrap {
            display: flex;
            align-items: center;
            @media #{$xs-layout} {
                margin: 0 0 15px;
            }
            @media #{$sm-layout} {
                margin: 0 0 0px;
            }
            span {
                font-family: $proximanova600;
                margin-right: 16px;
                display: inline-block;
            }
            ul {
                display: flex;
                li {
                    margin-right: 10px;
                    &:last-child {
                        margin-right: 0;
                    }
                    a {
                        display: inline-block;
                        font-family: $proximanova;
                        font-size: 11px;
                        color: #434343;
                        border: 1px solid #D3D3D3;
                        padding: 6px 21px;
                        @media #{$xs-layout} {
                            padding: 6px 15px;
                        }
                        &:hover {
                            color: $white;
                            background-color: $theme-color;
                            border: 1px solid $theme-color;
                        }
                    }
                }
            }
        }
        .blog-share-wrap {
            display: flex;
            align-items: center;
            span {
                font-family: $proximanova600;
                margin-right: 16px;
                display: inline-block;
            }
            ul {
                display: flex;
                li {
                    margin-right: 20px;
                    &:last-child {
                        margin-right: 0;
                    }
                    a {
                        display: inline-block;
                        svg {
                            path {
                                stroke: #C6C6C6;
                                transition: all .3s ease 0s;
                            }
                        }
                        &:hover {
                            &.facebook {
                                svg {
                                    path {
                                        stroke: #3b5999;
                                    }
                                }
                            }
                            &.twitter {
                                svg {
                                    path {
                                        stroke: #55acee;
                                    }
                                }
                            }
                            &.youtube {
                                svg {
                                    path {
                                        stroke: #cd201f;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .blog-prev-next-wrap {
        display: flex;
        justify-content: space-between;
        @media #{$xs-layout} {
            display: block;
        }
        @media #{$sm-layout} {
            display: flex;
        }
        .blog-prev-wrap {
            .blog-prev-img-content {
                display: flex;
                align-items: center;
                .blog-prev-img {
                    flex: 0 0 70px;
                    overflow: hidden;
                    img {
                        width: 100%;
                        transition: all .4s ease 0s;
                    }
                }
                .blog-prev-content {
                    margin-left: 20px;
                    h5 {
                        font-size: 14px;
                        font-weight: bold;
                        margin: 0 0 2px;
                        line-height: 20px;
                        width: 80%;
                    }
                    span {
                        font-size: 10px;
                        font-family: $proximanova;
                    }
                }
                &:hover {
                    .blog-prev-img {
                        img {
                            transform: scale(1.1);
                        }
                    }
                }
            }
            > a {
                font-size: 10px;
                text-transform: uppercase;
                display: inline-block;
                font-weight: bold;
                font-family: $proximanova;
                letter-spacing: 0.71px;
                margin: 24px 0 0;
            }
        }
        .blog-next-wrap {
            text-align: right;
            @media #{$xs-layout} {
                margin: 20px 0 0;
            }
            @media #{$sm-layout} {
                margin: 0px 0 0;
            }
            .blog-next-img-content {
                display: flex;
                align-items: center;
                @media #{$xs-layout} {
                    justify-content: flex-end;
                }
                .blog-next-img {
                    flex: 0 0 70px;
                    overflow: hidden;
                    img {
                        width: 100%;
                        transition: all .4s ease 0s;
                    }
                }
                .blog-next-content {
                    margin-right: 20px;
                    h5 {
                        font-size: 14px;
                        font-weight: bold;
                        margin: 0 0 2px auto;
                        line-height: 20px;
                        width: 80%;
                    }
                    span {
                        font-size: 10px;
                        font-family: $proximanova;
                    }
                }
                &:hover {
                    .blog-next-img {
                        img {
                            transform: scale(1.1);
                        }
                    }
                }
            }
            > a {
                font-size: 10px;
                text-transform: uppercase;
                display: block;
                font-weight: bold;
                font-family: $proximanova;
                letter-spacing: 0.71px;
                margin: 24px 0 0;
            }
        }
    }
    .blog-details-comment {
        margin: 82px 0 64px;
        @media #{$md-layout} {
            margin: 50px 0 50px;
        }
        @media #{$xs-layout} {
            margin: 40px 0 25px;
        }
        h2 {
            font-size: 36px;
            font-weight: bold;
            color: #212121;
            margin: 0 0 45px;
            @media #{$md-layout} {
                font-size: 30px;
                margin: 0 0 30px;
            }
            @media #{$xs-layout} {
                font-size: 30px;
                margin: 0 0 25px;
            }
        }
        .single-blog-comment {
            display: flex;
            .blog-comment-img {
                flex: 0 0 76px;
                img {
                    width: 100%;
                }
            }
            .blog-comment-content {
                margin-left: 24px;
                border: 1px solid #E5E5E5;
                margin-bottom: 20px;
                padding: 34px 135px 30px 45px;
                @media #{$xx-layout} {
                    padding: 34px 50px 30px 45px;
                }
                @media #{$xl-layout} {
                    padding: 34px 50px 30px 45px;
                }
                @media #{$lg-layout} {
                    padding: 34px 50px 30px 45px;
                }
                @media #{$md-layout} {
                    padding: 34px 25px 30px 25px;
                }
                @media #{$xs-layout} {
                    margin-left: 15px;
                    padding: 27px 20px 30px 20px;
                }
                .name-date-wrap {
                    display: flex;
                    .name {
                        h3 {
                            font-size: 20px;
                            color: #212121;
                            font-weight: bold;
                            margin: 0;
                            @media #{$xs-layout} {
                                font-size: 18px;
                            }
                            a {
                                color: #212121;
                                &:hover {
                                    color: $theme-color;
                                }
                            }
                        }
                    }
                    .date {
                        margin-left: 14px;
                        a {
                            font-size: 11px;
                            display: inline-block;
                            color: #9B9B9B;
                            font-family: $proximanova;
                            &:hover {
                                color: $theme-color;
                            }
                        }
                    }
                }
                p {
                    font-size: 16px;
                    color: #212121;
                    line-height: 28px;
                    margin: 27px 0 15px;
                    font-family: inherit;
                    @media #{$xs-layout} {
                        margin: 10px 0 15px;
                    }
                }
                .reply {
                    a {
                        font-weight: 600;
                        color: #212121;
                        font-size: 12px;
                        font-family: proximanova600;
                        display: inline-block;
                        &:hover {
                            color: $theme-color;
                        }
                    }
                }
            }
        }
    }
    .blog-comment-form {
        h2 {
            font-size: 36px;
            font-weight: bold;
            color: #212121;
            margin: 0 0 49px;
            @media #{$md-layout} {
                font-size: 30px;
                margin: 0 0 30px;
            }
            @media #{$xs-layout} {
                font-size: 30px;
                margin: 0 0 25px;
            }
        }
        form {
            input , textarea {
                height: 60px;
                color: #212121;
                font-family: $proximanova;
                width: 100%;
                border: 1px solid #d1d1d1;
                padding: 2px 20px;
                background-color: transparent;
                &::-moz-input-placeholder {
                    color: #212121;
                    opacity: 1;
                }
                &::-webkit-input-placeholder {
                    color: #212121;
                    opacity: 1;
                }
            }
            textarea {
                height: 190px;
                padding: 25px 20px;
                @media #{$xs-layout} {
                    height: 140px;
                }
                &::-moz-textarea-placeholder {
                    color: #212121;
                    opacity: 1;
                }
                &::-webkit-textarea-placeholder {
                    color: #212121;
                    opacity: 1;
                }
            }
            .text-submit {
                input {
                    width: auto;
                    font-size: 20px;
                    color: #434343;
                    height: auto;
                    border: 1px solid #979797;
                    padding: 20px 40px 18px;
                    font-family: $proximanova;
                    transition: all .3s ease 0s;
                    @media #{$xs-layout} {
                        font-size: 16px;
                        padding: 14px 30px 12px;
                    }
                    &::-moz-input-placeholder {
                        color: #434343;
                        opacity: 1;
                    }
                    &::-webkit-input-placeholder {
                        color: #434343;
                        opacity: 1;
                    }
                    &:hover {
                        color: $white;
                        border: 1px solid $theme-color;
                        background-color: $theme-color;
                    }
                }
            }
        }
    }
}

@media #{$lg-layout} {
    .blog-details-area {
        &.pt-200 {
            padding-top: 170px;
        }
    }
}

@media #{$md-layout} {
    .pagination-style {
        &.mt-65 {
            margin-top: 0px;
        }
    }
    .blog-details-area {
        &.pb-185 {
            padding-bottom: 100px;
        }
        &.pt-200 {
            padding-top: 105px;
        }
    }
}

@media #{$xs-layout} {
    .pagination-style {
        &.mt-65 {
            margin-top: 0px;
        }
    }
    .blog-area {
        &.pb-150 {
            padding-bottom: 70px;
        }
    }
    .blog-details-area {
        &.pb-185 {
            padding-bottom: 70px;
        }
        &.pt-200 {
            padding-top: 145px;
        }
    }
}

@media #{$sm-layout} {
    .blog-details-area {
        &.pt-200 {
            padding-top: 105px;
        }
    }
}


