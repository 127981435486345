/*-----------------------------------------------------------------------------------

    Template Name: Hasphotography HTML Template
    Version: 1.0

-----------------------------------------------------------------------------------
    
    CSS INDEX
    ===================
	
    01. Template default CSS
    02. Header style
    03. Hero style
    04. Footer style
    05. About us style
    06. Section title style
    07. Breadcrumb style
    08. Contact us style
    09. Blog style
    10. Gallery style

-----------------------------------------------------------------------------------*/
@import "./bootstrap/functions";
@import "variabls";
@import "custom-variables";
@import "./bootstrap/bootstrap";

@import "common";
@import "header";
@import "hero";
@import "footer";
@import "about-us";
@import "section-title";
@import "breadcrumb";
@import "contact-us";
@import "blog";
@import "gallery";





















