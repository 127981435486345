/*----------------------------------------
  04. Footer style
----------------------------------------*/

.footer-padding-bottom {
	padding-bottom: 0 0 20px;
}

.footer-top-mrg {
	margin: 0 0 25px;
}
.footer-bottom-mrg {
    padding: 75px 0 11px;
    @media #{$xs-layout} {
        padding: 60px 0 11px;
    }
}

.social-style-1 {
    @media #{$xs-layout} {
        text-align: center;
    }
    a {
        display: inline-block;
        margin-right: 77px;
        position: relative;
        @media #{$lg-layout} {
            margin-right: 50px;
        }
        @media #{$md-layout} {
            margin-right: 50px;
        }
        @media #{$xs-layout} {
            margin-right: 50px;
        }
        @media #{$sm-layout} {
            margin-right: 25px;
        }
        &::before {
            content: '';
            position: absolute;
            right: -41px;
            top: 50%;
            transform: translateY(-50%) rotate(22deg);
            width: 1px;
            height: 12px;
            background: #9B9B9B;
            @media #{$lg-layout} {
                right: -28px;
            }
            @media #{$md-layout} {
                right: -28px;
            }
            @media #{$xs-layout} {
                right: -28px;
            }
            @media #{$sm-layout} {
                right: -16px;
            }
        }
        &:last-child {
            margin-right: 0;
            &::before {
                display: none;
            }
        }
    }
    &.social-style-mrg-top {
        margin: 40px 0 0;
        @media #{$md-layout} {
            margin: 22px 0 0;
        }
        @media #{$xs-layout} {
            margin: 0px 0 0;
        }
        @media #{$sm-layout} {
            margin: 23px 0 0;
        }
    }
    &.social-style-1-white {
        text-align: right;
        @media #{$md-layout} {
            text-align: center;
            margin: 5px 0 0;
        }
        @media #{$xs-layout} {
            text-align: center;
            margin: 5px 0 0;
        }
        a {
            color: #C6C6C6;
            &::before {
                background: #C6C6C6;
            }
            &:hover {
                color: $theme-color;
            }
        }
    }
}

.meta-wrap {
    ul {
        li {
            display: flex;
            margin: 0 0 11px;
            &:last-child {
                margin: 0 0 0;
            }
            span {
                color: #9B9B9B;
                font-size: 10px;
                font-family: $librebaskerville;
                min-width: 90px;
                margin-right: 13px;
            }
            a {
                font-size: 10px;
                font-family: $librebaskerville;
            }
        }
    }
    &.meta-wrap-mrg-left {
        margin-left: 60px;
        @media #{$xx-layout} {
            margin-left: -100px;
        }
        @media #{$xl-layout} {
            margin-left: -100px;
        } 
        @media #{$lg-layout} {
            margin-left: -50px;
        }
        @media #{$md-layout} {
            margin-left: 0px;
        }
        @media #{$xs-layout} {
            margin-left: 0;
            display: flex;
            justify-content: center;
            margin-top: 30px;
        }
        @media #{$sm-layout} {
            margin-top: 0px;
        }
    }
}

.copyright {
    p {
        font-size: 10px;
        color: #9B9B9B;
        font-family: $librebaskerville;
        margin: 0;
        @media #{$xs-layout} {
            line-height: 27px;
        }
        a {
            color: #9B9B9B;
            font-family: $librebaskerville;
            &:hover {
                color: $theme-color;
            }
        }
        svg {
            margin: 0 5px;
            g {
                path {
                    stroke: $theme-color;
                }
            }
        }
    }
    &.copyright-white {
        p {
            color: #CFCFCF;
            a {
                color: #CFCFCF;
                &:hover {
                    color: $theme-color;
                }
            }
        }
    }
    &.copyright-white-2 {
        p {
            color: #C6C6C6;
            @media #{$xs-layout} {
                line-height: 27px;
            }
            a {
                color: #C6C6C6;
                &:hover {
                    color: $theme-color;
                }
            }
        }
    }
    &.aside-copyright {
        p {
            color: #CFCFCF;
            a {
                color: #CFCFCF;
                &:hover {
                    color: $theme-color;
                }
            }
            svg {
                margin: 0 5px;
                g {
                    path {
                        stroke: $theme-color;
                    }
                }
            }
        }
    }
    @media #{$md-layout} {
        text-align: center;
    }
    @media #{$xs-layout} {
        text-align: center;
    }
}
.footer-logo {
	margin: 86px 0 0;
    @media #{$md-layout} {
        margin: 62px 0 0;
    }
    @media #{$xs-layout} {
        margin: 0px 0 35px;
    }
    @media #{$sm-layout} {
        margin: 40px 0 35px;
    }
}

.footer-info {
    margin-left: 75px;
    @media #{$lg-layout} {
        margin-left: 0px;
    }
    @media #{$md-layout} {
        margin-left: 0px;
    }
    @media #{$xs-layout} {
        margin-left: 0px;
    }
    ul {
        li {
            color: #EFEFEF;
            display: flex;
            align-items: center;
            color: #EFEFEF;
            font-size: 18px;
            margin: 0 0 39px;
            @media #{$xs-layout} {
                margin: 0 0 16px;
                font-size: 15px;
            }
            span {
                color: #EFEFEF;
                font-size: 18px;
                margin-right: 5px;
                @media #{$xs-layout} {
                    font-size: 15px;
                }
            }
            a {
                color: #EFEFEF;
                font-size: 18px;
                @media #{$xs-layout} {
                    font-size: 15px;
                }
                &:hover {
                    color: $theme-color;
                }
            }
        }
    }
}

.subscribe-wrap {
    margin: 25px 0 0 42px;
    @media #{$lg-layout} {
        margin: 25px 0 0 20px;
    }
    @media #{$md-layout} {
        margin: 25px 0 0 0px;
    }
    @media #{$xs-layout} {
        margin: 0px 0 0 0px;
    }
    h4 {
        font-size: 18px;
        color: #EFEFEF;
        margin: 0 0 30px;
        @media #{$xs-layout} {
            margin: 0 0 18px;
        }
    }
    .subscribe-form {
        form {
            position: relative;
            .mc-form {
                input {
                    font-family: $proximanova;
                    color: $white;
                    width: 100%;
                    font-size: 14px;
                    background-color: transparent;
                    border: none;
                    padding: 2px 80px 6px 0;
                    border-bottom: 1px solid #D8D8D8;
                    &::-moz-input-placeholder {
                        color: $white;
                        opacity: 1;
                    }
                    &::-webkit-input-placeholder {
                        color: $white;
                        opacity: 1;
                    }
                }
                .mc-news {
                    display: none;
                }
                .clear {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    input {
                        width: auto;
                        border: none;
                        font-size: 14px;
                        padding: 0;
                        color: $white;
                        &:hover {
                            color: $theme-color;
                        }
                    }
                }
            }
        }
    }
}

.footer-position {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 9;
    padding: 13px 0 14px;
    background-color: rgba(15, 15, 15, 0.3);
}

@media #{$md-layout} {
    .footer-bottom {
        &.text-center {
            padding-bottom: 15px;
        }
    }
}

@media #{$xs-layout} {
    .footer-area {
        &.pt-95 {
            padding-top: 70px;
        }
    }
    .footer-bottom {
        &.text-center {
            padding-bottom: 15px;
        }
    }
}





